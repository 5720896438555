@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
// app global css in SCSS form
*:not(input):not(textarea) {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.background-contain {
  background-size:contain;
}
.background-no-repeat {
  background-repeat: no-repeat;
}

.message_alert {
	font-family: $principal;
	font-size: 12px;
	font-weight: 900;
	float: left;
	color: #d30000;
	width: 100%;
	background: $gray-lighter;
	padding: 5px 5px 3px 5px;
	margin-top: -5px;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
}

.valign-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.space-between {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.green-btn {
  border: 0px;
  border-radius: 4px;
  border: 1px solid $green;
  background-color: $green;
  font-family: $principal;
  color: $white;
  font-size: 14px;
  font-weight: 900;
  padding: 5px 25px;
  cursor: pointer;
  width: 100%;
  outline: 0;
}

.green-border-btn {
  border: 0px;
  border-radius: 4px;
  border: 1px solid $green;
  background-color: $white;
  font-family: $principal;
  color: $green;
  font-size: 14px;
  font-weight: 900;
  padding: 10px 10px;
  cursor: pointer;
  width: 100%;
  outline: 0;
}

.orange-btn {
  border: 0px;
  border-radius: 4px;
  border: 1px solid $orange;
  background-color: $orange;
  font-family: $principal;
  color: $white;
  font-size: 14px;
  font-weight: 900;
  padding: 9px 25px;
  cursor: pointer;
  width: 100%;
  outline: 0;
}

.col-5 {
	width: 47%;
}

@media (max-width: $breakpoint-xs-max) {
	.col-sm-12 {
		width: 100%;
	}
}

.form__container {
  .form__container-group {
    font-family: $principal;
    margin-bottom: 10px;

    label {
      width: 100%;
      font-weight: 900;
      font-size: 14px;
      margin-bottom: 5px;
      float: left;
    }

    input {
      border: 0px;
      border-radius: 6px;
      background-color: $gray-lighter;
      outline: 0;
      padding: 5px 10px;
      height: 41px;
      width: 100%;
    }

    textarea {
      border: 0px;
      border-radius: 6px;
      background-color: $gray-lighter;
      outline: 0;
      padding: 5px 10px;
      width: 100%;
      height: 41px;
      resize: none;
    }

    small {
      font-family: $principal;
      color: $orange;
    }

    img {
      width: 100%;
    }
  }
}

.native-controls-hidden {

}

.native-controls-hidden input::-webkit-outer-spin-button,
.native-controls-hidden input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.native-controls-hidden input[type=number] {
  -moz-appearance: textfield;
}

.native-controls-hidden i.text-negative {
  display: none;
}
