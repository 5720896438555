@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

  .item__section{
    display: flex;
    justify-content: center;
  }
  .price__ellipsis{
    // position: relative;
    // display: flex;
    // width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden!important;
  }
  .card_product{
    position: relative;
    // width: 33%;
    width: 100%;
    // padding: 0, i!important;
  }
  .product_item{
    position: relative;
    margin: 0 10px;
    padding: 30px 0px 0px 0px;
    border-radius: 6px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.3);
    text-align: center;
    background-color: white;

    .item__labels {
      .item__discount {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 10;
        background-color: $red;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
        border-radius: 500px;
        padding: 15px 5px;
        width: 45px;
        height: 45px;
        @include font(12px, 900, $white);
      }
      .item__season {
        position: absolute;
        top: 15px;
        left: -10px;
        background-color: $red;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
        border-radius: 0px;
        padding: 8px 15px;
        min-width: 100px;
        @include font(12px, 900, $white);
      }
      .item__season_gift {
        position: absolute;
        top: 15px;
        left: -10px;
        background-color: $green;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
        border-radius: 0px;
        padding: 8px 15px;
        min-width: 100px;
        @include font(12px, 900, $white);
      }
    }

    img.item__image {
      display: inline-block;
      padding-top: 25px;
      max-height: 160px;
      width: 100%;
      height: auto;
      text-align: center;
    }

    .item__image {
      display: inline-block;
      padding-top: 25px;
      max-height: 160px;
      text-align: center;
    }

    .item__info {
      margin-bottom: 15px;
      .item__price {
        @include font(20px, 900, $green);
        text-align: left;
        padding: 0px 20px;
      }
      .item__price-original {
        // background-color: red;
        position: relative;
        @include font(16px, 900, $border-gray);
        text-align: left;
        margin-top: auto;
        margin-bottom: auto;
        text-decoration: line-through;
        padding: 0px 10px;
        text-decoration-color: $red;
      }

      .item__title {
        @include font(16px, 500, $black);
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0px 20px;
      }
    }

    .item__button {
      background-color: $green;
      padding: 5px 45px;
      @include font(16px, 900, $white);
      text-align: center;
      display: flex;
      margin-left: 20px;
      text-transform: capitalize;
      border-radius: 10px !important;
    }

    .on__basket {
      margin-top: 15px;
      span {
        margin-left: 5px;
        margin-right: 15px;
        @include font(14px, 900, $gray-light);
      }
    }

    .soldout {
      margin-top: 15px;
      span {
        margin-left: 5px;
        margin-right: 15px;
        @include font(15px, 900, $red);
      }
    }

    .soldout-featured {
      margin-top: 15px;
      span {
        margin-left: 5px;
        margin-right: 15px;
        @include font(15px, 900, $white);
      }
    }

    &.green{ background-color: #7bb127; }
    &.yellow{ background-color: #ffd74d; }
    &.green, &.yellow {
      .item__info {
        .item__price, .item__title, .item__price-original {
          color: $white;
        }
      }

      .plus-btn {
        border: 0px;
        background: transparent;
        outline: 0;

        i {
          font-size: 38px;
        }
      }

      .on__basket span { color: white; }
      .icon-canasta2:before { color: white; }
    }

    @media (max-width: 991px) {
      // &.green, &.yellow { padding-bottom: 15px; }
    }

    @media (max-width: $breakpoint-xs-max) {
      padding-bottom: 15px;
      margin: 0 10px;
      padding-bottom: 20px;
      &.green, &.yellow { box-shadow: none; }

      img.item__image {
        height: 100px;
        padding: 15px;
      }

      .item__info {
        .item__price {
          font-size: 14px;
        }

        .item__price-original {
          font-size: 10px;
        }

        .item__title {
          font-size: 17px;
        }

        .item__button {
          margin-top: 10px;
          font-size: 12px;
          padding: 5px 15px;
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
     .card_product{
       position: relative;
       width: 95%;
       margin: 10px 10px;
       background-color: white;
       border-radius: 10px;
       padding: 10px 10px;
      //  min-height: 450px!important;
      //  background-color: red;
      //  padding: 0, i!important;
     }
     .item__section{
      display: flex;
      justify-content: center;
     }
     .item__button {
      margin: 0!important;
      padding: 4% 15% !important;
     }
     .product_item{
      margin: 0!important;
      min-height: 450px!important;
      // background-color: red;
     }
  }
