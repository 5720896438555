@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

    body.login {
        background-image: none !important;
        background-color: white;
    }

    .row {
        padding: 0 5%;
    }

    .card__header {
      padding-top: 15px;

      .on__basket {
        span {
          margin-left: 5px;
          margin-right: 15px;
          font-family: $principal;
          font-size: 14px;
          font-weight: 900;
          color: $gray-light;
        }
      }
    }

    .left-side {
      padding: 10px;
    }

    .add__product-image {
      width: 100%;
      max-width: 100%;
      height: auto;
      padding: 10px;
      margin-bottom: 9px;
    }

    .right-side {
      padding: 10px;

      h4 {
        margin: 0px 0px 10px 0px;
        font-family: $principal;
        font-weight: bold;
        font-size: 15px;
        // background-color: red;
        max-height: 20%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        // font-style: bold;
      }

      p {
        margin: 0px 0px 5px 0px;
        font-family: $principal;
        font-weight: lighter;
        font-size: 14px;
        width: 100%;
        float: left;

        span {
          float: right;
          font-size: 18px;
        }
      }

      p.notes {
        margin-top: 25px;
      }

      p.description {
        margin: 0px 0px 15px 0px;
      }

      .product-textarea.q-textarea {
        padding: 0 0.5%;
      }

      /*textarea {
        color: $gray;
        background-color: $gray-lighter;
        border: 0px;
        resize: none;
        float: left;
        width: 100%;
        border-radius: 6px;
      }*/

      .q-checkbox {
        padding: 0px !important;
        margin-left: -10px;
      }

      .q-checkbox__inner--active, .q-checkbox__inner--indeterminate {
        color: $green;
        color: $green;
      }

      .q-checkbox__label {
        font-family: $principal;
        font-weight: 900;
      }

      button.add {
        float: left;
        width: 100%;
        margin: 25px 0px 10px 0px;
        color: $white;
        background-color: $green;
        text-transform: none;
        border-radius: 6px;
        padding: 10px 25px;
        border: 0px;
        font-family: $principal;
        font-weight: 900;
        font-size: 16px;
      }
      button.add_promo {
        float: left;
        width: 100%;
        margin: 5px 0px 10px 0px;
        color: $white;
        background-color: $orange;
        text-transform: none;
        border-radius: 6px;
        padding: 1px 15px;
        border: 0px;
        font-family: $principal;
        font-weight: 900;
        font-size: 14px;
      }
    }

    .add__product-image {
      width: 100%;
      max-width: 100%;
      height: auto;
      padding: 10px;
      margin-bottom: 9px;
    }

    .quantity__container {
      display: inline-block;
      text-align: center;
      .quantity__container-input {
        position: relative;
        width: auto;
        display: initial;

        .quantity__type {
            position: absolute;
            right: 13px;
            border-radius: 0px;
            width: 20px;
            background: transparent;
            height: 40px;
            padding: 12px 0px;
            cursor: pointer;
        }

        .quantity__choice {
            position: absolute;
            right: 12px;
            width: 93px;
            border-radius: 6px;
            box-shadow: 1px 1px 15px rgba(0,0,0,0.1);
            top: -90px;
            background-color: $white;

            button {
                width: 100%;
                border-radius: 6px;
                background: $white;
                color: $black;
                font-family: $principal;
                font-size: 12px;
                text-align: left;
                padding: 0px 15px;
                cursor: pointer;

                span {
                    width: 30px;
                    img {
                        height: 15px;
                        margin-right: 10px;
                        margin-bottom: -2px;
                        text-align: left;
                    }
                }
            }
        }
      }
      .input_quantity {
        width: calc(100% - 100px);
        height: 35px;
        display: inline-block;
        text-align: center;
        font-family: $principal;
        font-weigth: 900;
      }

      .q-field__control-container {
        height: 40px;
        outline: 0;
      }

      .q-field__native {
        font-weight: 900;
      }

      .q-field--outlined .q-field__control {
        padding-right: 21px;
        height: 40px;
      }

      .q-field--outlined .q-field__control:after {
        border: 0px;
        height: 40px;
      }

      .q-field--outlined .q-field__control:before {
        height: 40px;
        border: 0px;
        background-color: $gray-lighter;
      }

      button {
        border-radius: 500px;
        height: 35px;
        width: 35px;
        border: 0px;
        outline: 0;
        color: white;
        font-weight: 900;
        display: inline-block;
        text-align: center;
      }

      .minus {
        background-color: $orange;
        touch-action: manipulation;
      }

      .plus {
        background-color: $green;
        touch-action: manipulation;
      }
    }

    .quantity__legend {
      font-family: $principal;
      font-size: 12px;
      color: $red;
      line-height: 14px;
    }

    .add__product-modal {
        min-width: 660px;
        border-radius: 16px;
        .q-toolbar {
            padding: 13px 15px !important;
        }
    }

    @media (max-width: $breakpoint-xs-max) {

        .q-toolbar__title:first-child {
            width: 100%;
            float: left;
            padding: 0px;
            margin: 0px;
            text-align: center;

            img {
                text-align: center;
            }
        }

        .q-card__section {
            padding: 0px 10px;
        }

        .add__product-modal {
            min-width: 90%;
            padding: 15px 0px;
            height: 100%!important;

            .q-card__section {
                padding: 0px;
            }

            .card__header {
                padding: 0 10px;
            }

            .left-side {
                width: 100%;
            }

            .right-side {
                width: 100%;

                p {
                    span {
                        font-size: 16px;
                        font-weight: 900;
                    }
                }
            }
        }

        .quantity__container {
          width: 100%;
          .quantity__container-input {
            .quantity__type {
              right: 18px;
            }
            .quantity__choice {
              position: absolute;
              right: 19px;
              width: 80%;
              button {
                padding: 0px 25px;
              }
            }
          }

          .q-field--outlined .q-field__control {
            width: 100% !important;
            min-width: 100% !important;
            max-width: 100% !important;
          }
          .q-field--outlined .q-field__control:before {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
          }
        }

    }
